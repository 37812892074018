function calcAbsoluteDifference (reduced, subtracted) {
  return reduced - subtracted
}

function calcPercent (numerator, denominator, fixed = 4) {
  return ((numerator / denominator) * 100).toFixed(fixed)
}

export {
  calcAbsoluteDifference,
  calcPercent
}
