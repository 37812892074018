<template>
  <div class="block__subtitle mb-40">{{ title }}</div>
  <div class="flex mb-40">
    <VDateInput
      is-range
      placeholder="Выберете период"
      v-model="dateRange"
      @change="setDateRange"
      @input="onSubmitDateRange"
      @reset="onResetDateRange"
    />

    <VFilter
      @onFilter="onFilter"
      @onReset="onResetFilter"
    >
      <VSelect
        placeholder="Компания"
        v-model="filter.companies"
        id="companies"
        name="companies"
        :options="manualCompanies"
        multiple
      />
    </VFilter>
  </div>

  <SpinnerPage v-if="isLoading" />

  <TradeStatisticsWork
    v-if="!isLoading && showStatistic"
    class="mb-60"
    :period="period"
    :statistics="statisticsTrade"
  />

  <div v-if="!isLoading && showStatistic" class="block__subtitle-small mb-40">
    Отчет по всем товарным позициям ТП за  выбранный период
  </div>

  <VTable
    v-if="!isLoading && showStatistic"
    class="mb-60"
    :head-row="tableHead"
    :rows="tableRows"
    :is-checkbox="false"
    :is-pagination="false"
    :is-touch-bar="false"
  />
</template>

<script setup>
import SpinnerPage from '@/components/views/Spinner/SpinnerPage'
import TradeStatisticsWork from '@/components/views/Organizer/VOrganizerTradeStatisticsWork'
import VDateInput from '@/components/ui/form/VDateInput'
import VTable from '@/components/ui/table/VTable'
import VFilter from '@/components/ui/modal/filter/VFilter'
import VSelect from '@/components/ui/form/VSelect'

import { computed, ref, defineProps, reactive, onMounted } from 'vue'
import { Alert } from '@/extension/Alert/Alert'
import useLoading from '@/use/Base/useLoading'
import { getTradeOfferStatistic } from '@/http/offer/offerApi'
import { formatDate, formatDateForServer } from '@/utils/format/date'
import { getIds, syncArrayWithData } from '@/utils/array'
import { refreshArr } from '@/utils/reactive'
import { findOfferBy } from '@/utils/model/Lot/find'
import { calcAbsoluteDifference, calcPercent } from '@/utils/number'
import { formatCurrency, formatPercent } from '@/utils/format/number'
import { getManualId } from '@/http/manualApi'

defineProps({
  title: {
    type: String,
    required: true
  }
})

const statisticsTrade = [
  { key: 'lot_create_count', label: 'Общее кол-во созданных ТП', text: '-' },
  { key: 'lot_finish_count', label: 'Общее количество завершенных ТП', text: '-' },
  { key: 'sum', label: 'Экономия по всем товарным позициям завершенных ТП за период в суммовом выражении', text: '-' },
  { key: 'sum_procent', label: 'Экономия по всем товарным позициям завершенных ТП за период в % отношении', text: '-' },
  { key: 'profit_count', label: 'Кол-во завершенных ТП с повышением конечной цены относительно стартовой', text: '-' },
  { key: 'profit_procent', label: 'Процентное соотношение завершенных ТП с повышением конечной цены относительно стартовой', text: '-' },
  { key: 'loss_count', label: 'Кол-во завершенных ТП с понижением конечной цены относительно стартовой', text: '-' },
  { key: 'loss_procent', label: 'Процентное соотношение завершенных ТП с понижением конечной цены относительно стартовой', text: '-' },
  { key: 'lot_not_happen', label: 'Количество ТП, признанных несостоявшимися', text: '-' },
  { key: 'lot_active', label: 'Кол-во активных ТП', text: '-' }
]

const tableHead = [
  {
    name: 'Начальная цена',
    value: 'priceStart'
  },
  {
    name: 'Конечная цена',
    value: 'priceEnd'
  },
  {
    name: 'Экономия в суммовом выражении',
    value: 'economyAbsolute'
  },
  {
    name: 'Экономия в % отношении',
    value: 'economyRelative'
  }
]
const tableRows = reactive([])
const { isLoading, startLoading, finishLoading } = useLoading()

const filter = reactive({
  companies: []
})
const manualCompanies = reactive([])

const period = computed(() => {
  if (!dateRange.value) return ''
  return formatDate(dateRange.value.start) + ' - ' + formatDate(dateRange.value.end)
})

onMounted(loadManual)

const showStatistic = ref(false)
function setShowStatistic (bool = true) {
  showStatistic.value = bool
}

const dateRange = ref(null)
function setDateRange (date) {
  dateRange.value = date
}
async function onSubmitDateRange (_, date) {
  if (!date) {
    setShowStatistic(false)
    return showWarning()
  }
  await loadStatistic()
}
function onResetDateRange () {
  setShowStatistic(false)
}
async function onFilter () {
  if (!dateRange.value) {
    await showWarning()
    return
  }
  await loadStatistic()
}
async function onResetFilter () {
  resetFilter()
  if (!dateRange.value) return
  await loadStatistic()
}
function resetFilter () {
  refreshArr(filter.companies, [])
}

async function loadStatistic () {
  try {
    startLoading()
    refreshArr(tableRows, [])

    const params = {
      start: formatDateForServer(dateRange.value.start),
      stop: formatDateForServer(dateRange.value.end),
      company_ids: getIds(filter.companies)
    }

    const { data } = await getTradeOfferStatistic(params)

    syncArrayWithData(statisticsTrade, data)
    setTableRow(data.lots)
    setShowStatistic(true)
  } catch (error) {
    await Alert.show('error', 'Не удалось загрузить статистику')
  } finally {
    finishLoading()
  }

  function setTableRow (lots) {
    const positionsAll = []
    for (const lot of lots) {
      const lotPositions = lot.position.map(item => {
        const offer = findOfferBy(item.id, lot.offers)

        const priceStart = +item.price * +item.quantity
        let priceEnd = 'Ставок нет'
        let economyAbsolute = '-'
        let economyRelative = '-'

        if (offer) {
          priceEnd = offer.price * offer.quantity

          const absolute = calcAbsoluteDifference(priceStart, priceEnd)
          economyAbsolute = formatCurrency(absolute)

          const percent = calcPercent(absolute, priceStart)
          economyRelative = formatPercent(percent)
        }

        return {
          id: item.id,
          priceStart,
          priceEnd,
          economyAbsolute,
          economyRelative
        }
      })

      positionsAll.push(...lotPositions)
    }

    refreshArr(tableRows, positionsAll)
  }
}
async function loadManual () {
  try {
    const { child } = await getManualId(13)
    refreshArr(manualCompanies, child)
  } catch (error) {
    await Alert.show('error', 'Не удалось загрузить справочник Компаний')
  }
}

function showWarning () {
  return Alert.show('warn', 'Выберете период')
}
</script>
